import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { ReactComponent as ArrowDown } from '@/assets/svg/arrow-down-small.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  faqItem: {
    name: StringField;
    text: StringField;
  };
  index: number;
};

function FaqItem({ faqItem, index }: Props) {
  const { text, name } = faqItem;

  const staticRef = useRef<HTMLDivElement>(null);
  const accordContentRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  function scrollTo(sectionId: number) {
    const targetElement = document.getElementById(String(sectionId));
    if (targetElement) {
      const y = targetElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  function toggleOpened(accordionId: number) {
    setOpen((opened) => !opened);
    if (!isOpen) {
      scrollTo(accordionId);
    }
  }

  useEffect(() => {
    if (!accordContentRef.current) return;

    const currentHeight = accordContentRef.current.offsetHeight;
    setMaxHeight(currentHeight + 100);
  }, []);

  useEffect(() => {
    if (!staticRef?.current) return;

    const linkList = staticRef.current.getElementsByTagName('a');
    for (let i = 0; i < linkList.length + 1; i++) {
      const link = linkList[i];
      const hrefLink = link?.getAttribute('href');

      if (hrefLink?.includes('./' || `${window.location.host}`)) return;

      link?.setAttribute('target', '_blank');
    }
  }, []);

  return (
    <Component id={String(index)}>
      <Inner>
        <Top onClick={() => toggleOpened(index)} isActive={isOpen}>
          <Title>{name}</Title>
          <ArrowDown />
        </Top>
        <Description isActive={isOpen} maxHeight={maxHeight}>
          <DescriptionInner ref={accordContentRef}>
            <Static
              ref={staticRef}
              dangerouslySetInnerHTML={{ __html: text ?? '' }}
            />
          </DescriptionInner>
        </Description>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  ${media.laptop(css`
    padding-bottom: 26px;
  `)}

  ${media.tablet(css`
    padding-bottom: 5px;
  `)}

  ${media.mobile(css`
    padding-bottom: 5px;
  `)}
`;

const Title = styled.span`
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  max-width: 90%;
  transition: all 0.5s ease-in-out;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
  `)}
  
   ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Top = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 0 58px;
  cursor: pointer;

  ${media.laptop(css`
    padding: 44px 0 16px;
  `)}

  ${media.tablet(css`
    padding: 20px 0 15px;
  `)}


  ${(props) =>
    props.isActive
      ? css`
          ${Title} {
            color: ${colors.orange};
          }
        `
      : ''}

  svg {
    position: relative;
    right: 10px;
    transition: all 0.4s ease-in-out;

    ${media.laptop(css`
      width: 22px;
      height: 20px;
    `)}

    ${media.tablet(css`
      right: 6px;
      width: 16px;
      height: 16px;
    `)}

    ${media.mobile(css`
      right: 5px;
      width: 14px;
      height: 14px;
    `)}

    ${(props) =>
      props.isActive
        ? css`
            transform: rotate(-180deg);
          `
        : ''}
  }
`;

const Description = styled.div<{ isActive?: boolean; maxHeight?: number }>`
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.4s linear, opacity 0.3s linear;

  ${(props) =>
    props.isActive
      ? css`
          visibility: visible;
          opacity: 1;
          max-height: ${props.maxHeight ? props.maxHeight + 'px' : 'none'};
        `
      : ''}
`;

const DescriptionInner = styled.div`
  padding-bottom: 54px;

  ${media.laptop(css`
    padding-bottom: 14px;
  `)}
`;

const Static = styled.div`
  h2 {
    margin: 0 0 42px;
    font-weight: 500;
    font-size: 72px;
    line-height: 80px;

    ${media.laptop(css`
      margin-bottom: 32px;
      font-size: 64px;
      line-height: 72px;
    `)}
  }

  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 58px;
    margin: 3px 0 34px;

    ${media.laptop(css`
      margin: 0 0 16px;
      font-size: 48px;
      line-height: 56px;
    `)}
  }

  h4 {
    margin: 84px 0 27px;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;

    ${media.laptop(css`
      margin: 56px 0 16px;
      font-size: 24px;
      line-height: 32px;
    `)}

    ${media.tablet(css`
      margin-top: 36px;
      font-size: 22px;
      line-height: 30px;
    `)}
    
     ${media.mobile(css`
      margin-top: 25px;
      font-size: 20px;
      line-height: 28px;
    `)}
  }

  p {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 34px;

    ${media.laptop(css`
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 26px;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 24px;
    `)}
    
     ${media.mobile(css`
      font-size: 16px;
      line-height: 22px;
    `)}

    &:last-child {
      margin-bottom: 0;
    }
  }

  table {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 34px;

    ${media.laptop(css`
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 26px;
    `)}
  }

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: ${colors.orange};
    text-decoration: underline;
    text-decoration-color: ${colors.orange};
    transition: all 0.3s linear;

    &:hover {
      text-decoration-color: transparent;
    }

    ${media.tablet(css`
      word-wrap: break-word;
    `)}
  }

  img {
    margin: 40px 0 20px;
    width: 100%;
    //height: 640px;
    object-fit: cover;
    border-radius: 36px;
    overflow: hidden;
    //background: ${colors.grayLight};

    ${media.laptop(css`
      margin: 24px 0 16px;
      //height: 480px;
      border-radius: 24px;
    `)}

    &+figcaption {
      margin-top: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: ${colors.grayLight};

      ${media.laptop(css`
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      `)}
    }
  }

  .image-style-side {
    float: left;
    margin: 0 20px 20px 0;

    img {
      margin: 0;
      width: auto;
      border-radius: 0;
    }
  }

  figure {
    margin-bottom: 34px;
    ${media.laptop(css`
      margin-bottom: 26px;
    `)}
  }

  ul,
  ol {
    margin-bottom: 84px;

    ${media.laptop(css`
      margin-bottom: 56px;
    `)}

    ${media.tablet(css`
      margin-bottom: 40px;
    `)}
    
    ${media.mobile(css`
      margin-bottom: 28px;
    `)}
  }

  ul {
    li {
      position: relative;
      text-indent: 40px;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 24px;

      &:before {
        position: absolute;
        content: '';
        top: 12px;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: ${colors.orange};
      }

      ${media.laptop(css`
        margin-bottom: 0;
        text-indent: 28px;
        font-size: 20px;
        line-height: 40px;

        &:before {
          top: 16px;
          width: 8px;
          height: 8px;
        }
      `)}

      ${media.tablet(css`
        font-size: 18px;
        line-height: 35px;
      `)}
      
       ${media.mobile(css`
        font-size: 16px;
        line-height: 33px;
      `)}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: list;
    li {
      position: relative;
      text-indent: 40px;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 24px;

      &:before {
        position: absolute;
        counter-increment: list;
        content: counter(list) '.';
        font-weight: 600;
        top: 0;
        left: -38px;
        color: ${colors.orange};
      }

      ${media.laptop(css`
        margin-bottom: 0;
        text-indent: 28px;
        font-size: 20px;
        line-height: 40px;

        &:before {
          left: -28px;
        }
      `)}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Component = styled.div`
  border-bottom: 2px solid ${colors.grayLight};

  ${media.laptop(css`
    border-bottom: 1px solid ${colors.grayLight};
  `)}

  ${media.laptopUp(css`
    &:hover {
      ${Title} {
        color: ${colors.orange};
      }
    }
  `)}

  &:last-child {
    border-bottom: none;

    ${DescriptionInner} {
      padding-bottom: 0;
    }
  }
`;

export default FaqItem;
